<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viajes Internos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Viajes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.8em"
                >
                  <thead>
                    <tr>
                      <th>Servicios</th>
                      <th>
                        #<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_viaje"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario Responsable
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.user"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Inicio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_inicio"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tip_ini in listasForms.tipos_inicio"
                            :key="tip_ini.tipo_inicio"
                            :value="tip_ini.tipo_inicio"
                          >
                            {{ tip_ini.tipo_inicio }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm text-nowrap"
                          v-model="filtros.fecha_inicio"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Fin
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_fin"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tip_ini in listasForms.tipos_fin"
                            :key="tip_ini.tipo_fin"
                            :value="tip_ini.tipo_fin"
                          >
                            {{ tip_ini.tipo_fin }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Fecha fin
                        <input
                          type="date"
                          class="form-control form-control-sm text-nowrap"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Origen
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Destino
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Conductor
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th colspan="2">
                        Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Odómetro Inicial</th>
                      <th>Odómetro Final</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in viajes.data" :key="item.id">
                      <td>
                        <div
                          class="btn-group dropright"
                          v-if="item.estado != 6"
                        >
                          <button
                            type="button"
                            class="btn btn-dark btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fas fa-align-justify"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              v-if="
                                item.tep_turno.tep_preliquidacion_internos
                                  .length == 0 &&
                                  ($store.getters.can(
                                    'tep.viajesInternos.edit'
                                  ) ||
                                    $store.getters.can(
                                      'tep.viajesInternos.editFechas'
                                    ))
                              "
                              @click="editarViaje(item)"
                            >
                              <i class="fas fa-edit"></i>
                              Editar Viaje
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-form-cancelar-viaje"
                              v-if="
                                $store.getters.can('tep.viajesInternos.edit') &&
                                  (item.estado == 1 || item.estado == 2)
                              "
                              @click="CancelarViaje(item)"
                            >
                              <i class="fas fa-backspace"></i>
                              Cancelar Viaje
                            </a>
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'tep.viajesInternos.odometroInicial'
                                ) &&
                                  (item.estado == 2 ||
                                    item.estado == 3 ||
                                    item.estado == 4 ||
                                    item.estado == 7)
                              "
                              @click="editOdometroInicial(item)"
                            >
                              <i class="fas fa-camera-retro"></i>
                              Diligenciar Odometro Inicial
                            </a>
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'tep.viajesInternos.odometroFinal'
                                ) &&
                                  (item.estado == 3 ||
                                    item.estado == 4 ||
                                    item.estado == 5 ||
                                    item.estado == 7)
                              "
                              @click="editOdometroFinal(item)"
                            >
                              <i class="fas fa-camera-retro"></i>
                              Diligenciar Odometro Final
                            </a>
                            <a
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modal-form-tep-viaje-inspeccion"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'tep.viajesInternos.inspeccionar'
                                ) &&
                                  (item.estado == 2 || item.estado == 3) &&
                                  !item.inspeccion &&
                                  item.solicitudes.tipo_operacion == 3
                              "
                              @click="llenarModalInspeccion(item, 1)"
                            >
                              <i class="fas fa-binoculars"></i>
                              Diligenciar Inspeccion
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-form-iniciar-viaje"
                              v-if="
                                $store.getters.can('tep.viajesInternos.edit') &&
                                  (item.estado == 2 || item.estado == 7) &&
                                  item.foto_odometro_ini !== null &&
                                  item.odometro_ini !== null
                              "
                              @click="IniciarViaje(item)"
                            >
                              <i class="fas fa-play"></i>
                              Iniciar Viaje
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              id="botonImp"
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-form-finalizar-viaje"
                              v-if="
                                $store.getters.can('tep.viajesInternos.edit') &&
                                  (item.estado == 4 || item.estado == 3) &&
                                  item.foto_odometro_fin !== null &&
                                  item.odometro_fin !== null
                              "
                              @click="FinalizarViaje(item)"
                            >
                              <i class="fas fa-check-double"></i>
                              Finalizar Viaje
                            </a>
                          </div>
                        </div>
                      </td>
                      <td class="text-center pl-4 pr-4">
                        <button
                          id="botonImp"
                          type="button"
                          class="btn btn-light btn-xs"
                          style="cursor: pointer"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepviajeinterno"
                          v-if="
                            $store.getters.can('tep.viajesInternos.detalle')
                          "
                          @click="llenarModal(item)"
                        >
                          {{ item.id }}
                        </button>
                      </td>
                      <td>{{ item.user ? item.user.name : "" }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-if="item.tipo_inicio"
                          :class="
                            item.tipo_inicio == 'web'
                              ? 'badge-info'
                              : item.tipo_inicio == 'app'
                              ? 'bg-navy'
                              : ''
                          "
                        >
                          {{ item.tipo_inicio }}
                        </span>
                      </td>
                      <td class="text-center text-nowrap">
                        {{ item.fecha_ini }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-if="item.tipo_fin"
                          :class="
                            item.tipo_fin == 'web'
                              ? 'badge-info'
                              : item.tipo_fin == 'app'
                              ? 'bg-navy'
                              : ''
                          "
                        >
                          {{ item.tipo_fin }}
                        </span>
                      </td>
                      <td class="text-center text-nowrap">
                        {{ item.fecha_fin }}
                      </td>
                      <td>{{ item.direccion_origen }}</td>
                      <td>{{ item.direccion_destino }}</td>
                      <td>
                        {{
                          item.transportadora
                            ? item.transportadora.razon_social
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          item.conductor
                            ? `${item.conductor.nombres} ${item.conductor.apellidos}`
                            : ""
                        }}
                        <span class="badge badge-info" v-if="item.conductor">{{
                          item.conductor.numero_documento
                        }}</span>
                      </td>
                      <td>{{ item.vehiculo ? item.vehiculo.placa : "" }}</td>
                      <td class="text-center">
                        <div v-if="item.vehiculo">
                          <div
                            v-if="
                              item.vehiculo.ultimo_registro &&
                                item.vehiculo.ultimo_registro !== undefined
                            "
                          >
                            <i
                              v-if="
                                item.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte < fechaCorte
                              "
                              class="fa fa-times-circle text-danger"
                              data-toggle="tooltip"
                              data-placement="right"
                              :title="
                                item.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              "
                            ></i>

                            <i
                              v-else
                              class="fa fa-check text-success"
                              data-toggle="tooltip"
                              data-placement="right"
                              :title="
                                item.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              "
                            ></i>
                          </div>
                          <div v-else>
                            <i
                              class="fa fa-times-circle text-danger"
                              data-toggle="tooltip"
                              data-placement="right"
                              title="Sin fecha de reporte"
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">{{ item.odometro_ini }}</td>
                      <td class="text-center">{{ item.odometro_fin }}</td>
                      <td class="text-center pl-5 pr-5">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'badge-info'
                              : item.estado == 2
                              ? 'badge-warning'
                              : item.estado == 3
                              ? 'badge-success'
                              : item.estado == 4
                              ? 'badge-primary'
                              : item.estado == 5
                              ? 'bg-navy'
                              : item.estado == 6
                              ? 'badge-danger'
                              : item.estado == 7
                              ? 'bg-teal'
                              : item.estado == 8
                              ? 'badge-danger'
                              : ''
                          "
                        >
                          {{ item.nEstado }}
                          {{
                            item.solicitudes.tep_solicitud_padre_id
                              ? `/ ${item.solicitudes.tep_solicitud_padre_id}`
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="viajes.total">
                  <p>
                    Mostrando del <b>{{ viajes.from }}</b> al
                    <b>{{ viajes.to }}</b> de un total:
                    <b>{{ viajes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="viajes"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepViajeInternoDetalle ref="TepViajeInternoDetalle" />
    <TepViajeInternoIniciar ref="TepViajeInternoIniciar" />
    <TepViajeInternoFinalizar ref="TepViajeInternoFinalizar" />
    <TepViajeInternoCancelar ref="TepViajeInternoCancelar" />
    <TepViajeInternoInspeccion ref="TepViajeInternoInspeccion" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepViajeInternoDetalle from "./TepViajeInternoDetalle";
import TepViajeInternoIniciar from "./TepViajeInternoIniciar";
import TepViajeInternoFinalizar from "./TepViajeInternoFinalizar";
import TepViajeInternoCancelar from "./TepViajeInternoCancelar";
import TepViajeInternoInspeccion from "./TepViajeInternoInspeccion";
import vSelect from "vue-select";
import moment from "moment";
export default {
  name: "TepViajeInternoIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    TepViajeInternoDetalle,
    TepViajeInternoIniciar,
    TepViajeInternoFinalizar,
    TepViajeInternoCancelar,
    TepViajeInternoInspeccion,
  },
  data() {
    return {
      cargando: false,
      selct_empresa: {},
      filtros: {
        id_viaje: null,
        conductor: null,
        user: null,
        origen: null,
        destino: null,
        vehiculo: null,
        estado: null,
        tipo_inicio: null,
        fecha_inicio: null,
        tipo_fin: null,
        fecha_fin: null,
      },
      viajes: {},
      listasForms: {
        estados: [],
        empresas: [],
        tipos_inicio: [],
        tipos_fin: [],
      },
      fechaAct: null,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;

      this.filtros.empresa_id = null;
      if (this.selct_empresa) {
        this.filtros.empresa_id = this.selct_empresa.id;
      }
      axios
        .get("/api/tep/viajesInternos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.viajes = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/109").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoInicio() {
      this.cargando = true;
      axios.get("/api/tep/viajes/tipos_inicio").then((response) => {
        this.listasForms.tipos_inicio = response.data;
        this.cargando = false;
      });
    },

    getTipoFin() {
      this.cargando = true;
      axios.get("/api/tep/viajes/tipos_fin").then((response) => {
        this.listasForms.tipos_fin = response.data;
        this.cargando = false;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [5],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    llenarModal(item) {
      this.$refs.TepViajeInternoDetalle.llenar_modal(item);
    },

    IniciarViaje(item) {
      this.$refs.TepViajeInternoIniciar.llenar_modal(item);
    },

    FinalizarViaje(item) {
      this.$refs.TepViajeInternoFinalizar.llenar_modal(item);
    },

    CancelarViaje(item) {
      this.$refs.TepViajeInternoCancelar.llenar_modal(item);
    },

    llenarModalInspeccion(viaje, tipo) {
      this.$refs.TepViajeInternoInspeccion.llenar_modal_inspeccion(viaje, tipo);
    },

    fechaActual() {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaAct)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    validaFechas() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    editarViaje(viaje) {
      return this.$router.push({
        name: "/Tep/ViajeInternoEdit",
        params: {
          accion: "Editar",
          data_viaje: viaje,
          id: viaje.id,
        },
      });
    },

    editOdometroInicial(viaje) {
      return this.$router.push({
        name: "/Tep/ViajeInternoOdoInicial",
        params: {
          accion: "Inicial",
          data_viaje: viaje,
          id: viaje.id,
        },
      });
    },

    editOdometroFinal(viaje) {
      return this.$router.push({
        name: "/Tep/ViajeInternoOdoFinal",
        params: {
          accion: "Final",
          data_viaje: viaje,
          id: viaje.id,
        },
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoInicio();
    this.getTipoFin();
    this.fechaActual();
  },
};
</script>
